/* globals adpageHeading */

require(['jquery', 'mojo-analytics'],
  function ($, MojoAnalytics) {
    var r = {
      $googleLogin: $('.login-google'),
      $facebookLogin: $('.login-facebook'),
    };

    var cta;

    function handleSocialLoginClick (signInMethod, signInType) {
      MojoAnalytics.sendEvent({
        eventName: 'Attempted Sign In',
        eventData: {
          signin_method: signInMethod,
          signin_intent: signInType,
        },
      });
    }

    if (window.location.pathname == '/accounts/login/') {
      cta = $('#cta-text').html();
      MojoAnalytics.sendEvent({
        eventName: 'Viewed Login Page',
        eventData: {
          cta_text: cta ? cta : null,
          offer_heading: adpageHeading ? adpageHeading : null,
        },
      });
    }

    $('.advert-cta').click(function () {
      cta = $('#cta-text').html();
      MojoAnalytics.sendEvent({
        eventName: 'Clicked Login CTA',
        eventData: {
          cta_text: cta ? cta : null,
          offer_heading: adpageHeading ? adpageHeading : null,
        },
      });
    });

    $('#app-banner').click(function () {
      MojoAnalytics.sendEvent({
        eventName: 'Clicked Item',
        eventData: {
          item_name: 'clicked banner',
          source: 'loginpage',
        },
      });
    });

    function bindAll () {
      r.$googleLogin.on('click', function () {
        handleSocialLoginClick('google', r.$googleLogin.data('signinType'));
      });

      r.$facebookLogin.on('click', function () {
        handleSocialLoginClick('facebook', r.$facebookLogin.data('signinType'));
      });
    }

    bindAll();

  });

define("social-login", function(){});

